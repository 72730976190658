import api from '../../api/assets'
const mixin = {
  data() {
    return {

    }
  },
  methods: {
    deleteAsset(row) {
      this.assetsName = row.name;
      this.dialogVisibleDelete = true;
      this.deleteId = row.id;
      this.$nextTick(() => {
        this.$refs.delete.show = true;
      });
    },
    deleteSure() {
      let data = {
        id: this.$route.query.id,
        market: "US",
      };
      api.discardDelete(data).then((res) => {
        if (res.data.code === 10200) {
          this.$router.push("/assets/all");
        }
        if (res.data.code === 10001) {
          this.$$error(res.data.message);
        }
      });
    },
  }
}

export default mixin;