<template>
  <div>
    <el-dialog
      top="12%"
      :visible.sync="show"
      width="458px"
      class="pop-dialog dialogTip"
      :close-on-click-modal="false"
    >
      <div style="paddingTop:40px">
        <span class="fw-500 fz-18">{{ title }}</span>
        <span class="fw-700 fz-24 pd-l-10">{{ boldTitle }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="ok">{{
          confirmText
        }}</el-button>
        <el-button @click="exit" class="cancel-button">{{
          cancelText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 通过 this.$nextTick(() => { this.$refs.alertRedPaper.show = true; }); 来控制此组件的显示隐藏
export default {
  props: {
    title: {
      type: String,
      default() {
        return ``;
      },
    },
    boldTitle: {
      type: String,
      default() {
        return ``;
      },
    },
    cancelText: {
      type: String,
      default() {
        return `取消`;
      },
    },
    cancel: {
      type: Function,
      default() {
        return () => {};
      },
    },
    needCancel: {
      type: Boolean,
      default() {
        return true;
      },
    },
    confirmText: {
      type: String,
      default() {
        return `确定`;
      },
    },
    confirm: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ok() {
      this.confirm();
      this.show = false;
    },
    exit() {
      this.cancel();
      this.show = false;
    },
  },
};
</script>
